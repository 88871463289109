import { Log } from 'ng2-logger/browser';
import { map, catchError } from 'rxjs/operators';
import { Email } from '../../interfaces/email';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from '../../interfaces/user';
import { JwtToken } from 'app_code/app/shared/interfaces/jwt-token';

@Injectable()
export class RegistrationService implements OnInit {

    private log = Log.create('RegistrationService');

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    ngOnInit() {
        this.authService.redirectLoggedInUser();
    }

    public register(user: User): Observable<JwtToken | HttpErrorResponse> {
        return this.http.post<JwtToken | HttpErrorResponse>('/rest/register', user);
    }

    public checkIfUsernameIsAvailable(username: string): Observable<any> {
        return this.http.get("rest/user/check/username/" + username)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public activateAccount(id: string): Observable<any> {
        return this.http.get('rest/register/activate' + id)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError))
    }

    public activateEmail(id: string): Observable<any> {
        return this.http.put('rest/user/email/' + id, null)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError))
    }

    public requestPasswordReset(email: Email): Observable<any> {
        return this.http.post('rest/login/reset/initiate', email)
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    public resetPassword(key: string, password: string): Observable<any> {
        return this.http.post('rest/login/reset', {key, password})
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    public resendRegistrationEmail(email: string, captcha: string): Observable<void> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        let emailObj: any = {
            email: email
        }
        return this.http.put('rest/register/resend/email', emailObj, {params: params})
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (errMsg === '409 - OK') {
            errMsg = 'Username/email already exists';
        }
        if (errMsg === '400 - OK') {
            errMsg = 'Invalid username';
        }
        this.log.data(errMsg);
        return throwError(errMsg);
    }
}
