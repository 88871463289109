import { Log } from 'ng2-logger/browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Area } from '../../interfaces/area';
import { PointContentDTO, PointDTO } from 'app_code/app/shared/model/point-dto';
import { PhotosDTO } from 'app_code/app/shared/model/photos-dto';
import { DisplayPointsBy } from 'app_code/app/ui/view-point-panel/model/display-point-by.enum';
import { MapService } from 'app_code/app/ui/map/services/map.service';

@Injectable()
export class PointService {

    page: PointDTO = null; // currently loaded page on map
    displayPointOnPanelBy = DisplayPointsBy.AREA;
    lastClickedPointOnMap$ = new BehaviorSubject<PointContentDTO>(null);
    isPoiDetailsOpen$ = new BehaviorSubject<PointContentDTO>(null);

    private pageToPointsList = new Map<number, PointDTO>();
    private pageToPointsListChanged$: ReplaySubject<Map<number, PointDTO>> = new ReplaySubject(1);
    private currentlyLoadedPage = 0;
    private poiIdToPageNumberList = new Map<number, number>();
    private lastUsedPoiSizeMeters: number;

    private log = Log.create('PointService');

    constructor(
        private authHttp: HttpClient,
        private mapService: MapService
    ) { }

    get pageToPointsListChanged() {
        return this.pageToPointsListChanged$.asObservable();
    }

    get currentlyLoadedPageIdx() {
        return this.currentlyLoadedPage;
    }

    set currentlyLoadedPageIdx(idx: number) {
        this.currentlyLoadedPage = idx;
    }

    getPageToPointsList() {
        return this.pageToPointsList;
    }

    getPageFromPageToPointsList(idx: number) {
        return this.pageToPointsList.has(idx) ? this.pageToPointsList.get(idx) : null;
    }

    getPageNumberFromPoiId(poiId: number): number {
        return this.poiIdToPageNumberList.get(poiId);
    }

    addNewPageToPointsList(points: PointDTO, idx: number) {
        if (points?.content?.length === 0) {
            return;
        }

        points.content.forEach(point => {
            this.poiIdToPageNumberList.set(point.poi.id, idx);
        });
      
        this.pageToPointsList.set(idx, points);
        this.currentlyLoadedPageIdx = idx;
        this.pageToPointsListChanged$.next(this.pageToPointsList);
    }

    updateCollectionFollowOnAllPointsList(collectionId: number, followed: boolean): void {
        this.pageToPointsList.forEach(el => {
            el.content.forEach(p => {
                if (p.id === collectionId) {
                    p.followed = followed;
                }
            })
        });
        this.pageToPointsListChanged$.next(this.pageToPointsList);
    }

    updatePointLikeOnAllPointsList(point: PointContentDTO): void {
        this.pageToPointsList.forEach(el => {
            const idx = el.content.findIndex(p => p.poi.id === point.poi.id);
            if (idx !== -1 && el[idx] &&  el[idx].poi) {
                el[idx].poi.liked = point.poi.liked;
                el[idx].poi.likes = point.poi.likes;
            }
        });
        this.pageToPointsListChanged$.next(this.pageToPointsList);
    }

    clearPageToPointsList() {
        this.poiIdToPageNumberList.clear();
        this.pageToPointsList.clear();
        this.currentlyLoadedPageIdx = 0;
        this.pageToPointsListChanged$.next(this.pageToPointsList);
    }

    // NOT USED YET
    // public getPointsProjectionByHashtag(hashtag: string, hidePublicPoints: boolean, page: number, size: number): Observable<PointDTO> {
    //     let query: any = {
    //         hashtag: hashtag,
    //         userAndFriendsCommentsOnly: hidePublicPoints
    //     }
    //     return this.authHttp.post("rest/pois" + "?page=" + page + "&size=" + size, query)
    //     .pipe(
    //         map(res => {
    //             return res;
    //         }),
    //         catchError(this.handleError))
    // }

    public getPointsProjectionByArea(area: Area, hidePublicPoints: boolean, page: number, getPoiSizeInMetersFromCache = false): Observable<PointDTO> {
        const {horizontal, vertical} = this.mapService.getNumberOfPointsThatFitsCurrentView();
        let poiSizeMeters;
        if (getPoiSizeInMetersFromCache) {
            poiSizeMeters = this.lastUsedPoiSizeMeters;
        } else {
            poiSizeMeters = this.mapService.getPointSizeInMeters();
            this.lastUsedPoiSizeMeters = poiSizeMeters;
        }

        let query: any = {
            ne: area.ne,
            sw: area.sw,
            horizontalNoOfPois: horizontal,
            verticalNoOfPois: vertical,
            poiSizeMeters,
            userAndFriendsCommentsOnly: hidePublicPoints,
            ownedAndFollowed: this.mapService.ownedAndMineFilter
        }
        return this.authHttp.post("rest/area/pois" + "?page=" + page + "&size=" + 1, query)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    // NOT USED YET
    // public getPointsProjectionByCollectionId(collectionId: number, page: number, size: number):  Observable<PointDTO> {
    //     return this.authHttp.get("rest/collection/" + collectionId + "/pois" + "?page=" + page + "&size=" + size)
    //     .pipe(
    //         map(res => {
    //             return res;
    //         }),
    //         catchError(this.handleError))
    // }

    public getPointsProjectionByAreaAndCollectionId(area: Area, collectionId: number, page: number, getPoiSizeInMetersFromCache = false): Observable<PointDTO> {
        const {horizontal, vertical} = this.mapService.getNumberOfPointsThatFitsCurrentView();
        let poiSizeMeters;
        if (getPoiSizeInMetersFromCache) {
            poiSizeMeters = this.lastUsedPoiSizeMeters;
        } else {
            poiSizeMeters = this.mapService.getPointSizeInMeters();
            this.lastUsedPoiSizeMeters = poiSizeMeters;
        }

        let query: any = {
            ne: area?.ne,
            sw: area?.sw,
            horizontalNoOfPois: horizontal,
            verticalNoOfPois: vertical,
            poiSizeMeters,
            collectionId: collectionId
        }
        return this.authHttp.post("rest/area/collection/pois" + "?page=" + page + "&size=" + 1, query)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    // NOT USED YET
    // public getPointsProjectionByAreaAndUserId(area: Area, userId: number, page: number, size: number): Observable<PointDTO> {
    //     let query: any = {
    //         ne: area.ne,
    //         sw: area.sw,
    //         userId: userId
    //     }
    //     return this.authHttp.post("rest/area/user/pois" + "?page=" + page + "&size=" + size, query)
    //     .pipe(
    //         map(res => {
    //             return res;
    //         }),
    //         catchError(this.handleError))
    // }

    public getPointsByUsername(userId: string, page: number, size: number): Observable<PointDTO> {
        return this.authHttp.get("rest/pois/" + userId + "?page=" + page + "&size=" + size)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getPointById(id: number): Observable<PointContentDTO> {
        return this.authHttp.get("rest/poi/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public addPoint(point: PointContentDTO): Observable<PointContentDTO> {
        const payload = {
            ...point.poi,
            collectionId: point.id
        };

        return this.authHttp.post('rest/poi', payload)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public updatePoint(point: PointContentDTO): Observable<void> {
        let updatedContent = {
            text: point.poi.text,
            title: point.poi.title,
            collectionId: point.id,
            share: point.poi.share,
            id: point.poi.id,
            address: point.poi.address,
            images: point.poi.images,
            lat: point.poi.lat,
            lng: point.poi.lng
        };
        return this.authHttp.put("rest/poi", updatedContent)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public deletePoint(point: PointContentDTO): Observable<PointContentDTO> {
       let params = new HttpParams().set("id", point.poi.id + "");

       return this.authHttp.delete("rest/poi", {params: params})

        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getImagesByUsername(userId: string, page: number, pageSize: number): Observable<PhotosDTO> {
        return this.authHttp.get("rest/pois/" + userId + "/images?page=" + page + "&size=" + pageSize)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public addLike(pointId: number): Observable<void> {
        return this.authHttp.post("rest/poi/" + pointId + "/likes", null)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public deleteLike(pointId: number): Observable<void> {
        return this.authHttp.delete("rest/poi/" + pointId + "/likes")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
        this?.log?.data(error);
        return throwError(error.message || "Server error");
    }

    public mergePoints(original: PointContentDTO[], merge: PointContentDTO[]): void {
        merge.forEach(point => {
            let pointExist: boolean = original?.some(el => { return point.poi.id == el.poi.id; });
            if (!pointExist) {
                original?.push(point);
            }
        });
    }

    public deletePointLocally(id: number): void {
        this.page.content = this.page.content.filter(item => item.poi.id !== id);
    }

}
