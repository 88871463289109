
<div 
  class="point-on-map"
  [ngClass]="{
    'point-on-map--minimalistic': showMinimalisticVersion, 
    'point-resize': !showMinimalisticVersion,
    'point-shrink': showMinimalisticVersion,
    'point-on-map--loading': blockEvents}"
  (click)="onPointClick($event)" 
  (wheel)="onWheel($event)"
  >
  <img *ngIf="point.poi.images && point.poi.images.length > 0" class="point-on-map__image" [src]="image_path + point.poi.images[0].image">

  <div *ngIf="point.poi.likes > 0" class="point-on-map__likes">
    <shout-svg-icons [name]="'markerFilledHearth'" ></shout-svg-icons>
    <div class="point-on-map__likes__number">{{point.poi.likes > 99 ? '99+' : point.poi.likes}}</div>
  </div>

  <div *ngIf="point.poi.images && point.poi.images.length > 0 && !showMinimalisticVersion" class="point-on-map__images-count">
    <shout-svg-icons [name]="'images'" size="xSmall"></shout-svg-icons>
    <div class="point-on-map__images-count__number">{{point.poi.images.length > 99 ? '99+' : point.poi.images.length }}</div>
  </div>

  <div *ngIf="!showMinimalisticVersion" class="point-on-map__title">{{point.poi.title}}</div>

  <div *ngIf="!isCollectionHintVisible && (searchType !== SearchType.COLLECTION || !hasSelectedItem)" class="point-on-map__collection-count">
    <span>{{point.numberOfPois > 99 ? '99+' : point.numberOfPois}}</span>
  </div>

  <div class="point-on-map__pointer" [ngClass]="{'pointer-on-map__pointer--poisCount': shouldShowPoisCounter}">
    <div class="point-on-map__pointer__element">
      @if (shouldShowPoisCounter) {
        <spaceshout-button
          [size]="'roundedAuto'"
          [label]="point.poisCount > 100 ? '100+' : point.poisCount"
          (clicked)="onPoisCountClick($event)"
        >
        </spaceshout-button>
      } @else {
        <shout-svg-icons [name]="'mapPointRectangle'"></shout-svg-icons>
      }
    </div>
  </div>
</div>



