import { Injectable } from '@angular/core';
import { LatLng } from 'app_code/app/shared/interfaces/latLng';
import { SearchOpenBy, SearchOpenTrigger } from './store-data.model';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { PointContentDTO } from '../../model/point-dto';
import { MapMarkersByAreaUpdate, MapMarkersByCollectionUpdate } from 'app_code/app/ui/map/model/map-markers-update';
import { Area } from '../../interfaces/area';
import { ActivePointOnMap, PointOnMapSize } from '../../enums/point-on-map';

@Injectable({
  providedIn: 'root'
})
export class StoreDataService {

  private selectedPoint$ = new BehaviorSubject<{point: LatLng, backup: LatLng, text?: string}>({point: null, backup: null, text: null});
  private activePointOnMap$ = new BehaviorSubject<Map<PointContentDTO, PointOnMapSize>>(null);
  private isWallOpen$ = new BehaviorSubject<boolean>(null);

  private searchOpenedBy$ = new BehaviorSubject<SearchOpenBy>({trigger: SearchOpenTrigger.MAP, value: null});
  private selectedAddress$ = new BehaviorSubject<string>('');
  private selectedAddressOverwritePanel$ = new Subject<boolean>();
  private selectedAddressOverwriteLabel$ = new Subject<string>();
  private newPoint$ = new Subject<PointContentDTO>();
  private mapRefresh$ = new Subject<void>();
  private moveMapToBounds$ = new Subject<Area>();
  private mapInitialMarkersLoadCompleted$ = new Subject<void>();
  private selectedCollectionId$ = new BehaviorSubject<number>(null);

  private pointsOnMapByAreaPagingData$ = new BehaviorSubject<MapMarkersByAreaUpdate>(null);
  private pointsOnMapByCollectionPagingData$ = new BehaviorSubject<MapMarkersByCollectionUpdate>(null);
  private pointsOnMapRendered$ = new Subject<void>();
  private loadPageOnMapFromMemory$ = new Subject<number>();
  private viewPointsPanelToggle$ = new BehaviorSubject<boolean>(null);

  private subscribedAreaPolygonPoints$ = new BehaviorSubject<L.LatLng[]>(null);
  private activateSubscribedArea$ = new BehaviorSubject<boolean>(null);
  private deactivateSubscribedArea$ = new BehaviorSubject<boolean>(null);
  
  private toggleLeftSideBar$ = new Subject<boolean>();

  private addCollectionNavBackUrl$ = new BehaviorSubject<string>(null);

  private goOutCollectionView$ = new Subject<void>();

  private isEmbedded$ = new BehaviorSubject<boolean>(false);

  get selectedPoint() {
    return this.selectedPoint$;
  }

  get activePointOnMap() {
    return this.activePointOnMap$;
  }

  get isWallOpen() {
    return this.isWallOpen$;
  }
  
  get searchOpenedBy() {
    return this.searchOpenedBy$;
  }

  get selectedAddress() {
    return this.selectedAddress$;
  }

  get selectedAddressOverwritePanel() {
    return this.selectedAddressOverwritePanel$;
  }

  get selectedAddressOverwriteLabel() {
    return this.selectedAddressOverwriteLabel$;
  }

  get newPoint() {
    return this.newPoint$;
  }

  get mapRefresh() {
    return this.mapRefresh$;
  }

  get moveMapToBounds() {
    return this.moveMapToBounds$;
  }

  get mapInitialMarkersLoadCompleted() {
    return this.mapInitialMarkersLoadCompleted$;
  }

  get selectedCollectionId() {
    return this.selectedCollectionId$;
  }

  get pointsOnMapByAreaPagingData() {
    return this.pointsOnMapByAreaPagingData$;
  }

  get pointsOnMapByCollectionPagingData() {
    return this.pointsOnMapByCollectionPagingData$;
  }

  get pointsOnMapRendered() {
    return this.pointsOnMapRendered$;
  }
  
  get loadPageOnMapFromMemory() {
    return this.loadPageOnMapFromMemory$;
  }  

  get viewPointsPanelToggle() {
    return this.viewPointsPanelToggle$;
  }  

  get subscribedAreaPolygonPoints() {
    return this.subscribedAreaPolygonPoints$;
  }

  get activateSubscribedArea() {
    return this.activateSubscribedArea$;
  }

  get deactivateSubscribedArea() {
    return this.deactivateSubscribedArea$;
  }
  
  get toggleLeftSideBar() {
    return this.toggleLeftSideBar$;
  }

  get addCollectionNavBackUrl() {
    return this.addCollectionNavBackUrl$;
  }

  get goOutCollectionView() {
    return this.goOutCollectionView$;
  }

  get isEmbedded() {
    return this.isEmbedded$;
  }

  setSelectedPoint(point) {
    this.selectedPoint$.next(point);
  }

  setActivePointOnMap(val: Map<PointContentDTO, PointOnMapSize>) {
    this.activePointOnMap$.next(val);
  }

  setIsWallOpen(val:boolean) {
    this.isWallOpen$.next(val);
  }

  setSearchOpenedBy(val: SearchOpenBy) {
    this.searchOpenedBy$.next(val);
  }

  setSelectedAddress(val) {
    this.selectedAddress$.next(val);
  }

  toggleSelectedAddressOverwritePanel(val: boolean) {
    this.selectedAddressOverwritePanel$.next(val);
  }

  setNewPoint(val) {
    this.newPoint$.next(val);
  }

  setSelectedAddressOverwriteLabel(val) {
    this.selectedAddressOverwriteLabel$.next(val);
  }

  setMapRefresh() {
    this.mapRefresh$.next();
  }

  setMoveMapToBounds(val) {
    this.moveMapToBounds$.next(val);
  }

  setMapInitialMarkersLoadCompleted() {
    this.mapInitialMarkersLoadCompleted$.next();
  }
  
  setSelectedCollectionId(val) {
    this.selectedCollectionId$.next(val);
  }

  setPointsOnMapByAreaPagingData(val: MapMarkersByAreaUpdate) {
    this.pointsOnMapByAreaPagingData$.next(val);
  }

  setPointsOnMapByCollectionPagingData(val: MapMarkersByCollectionUpdate) {
    this.pointsOnMapByCollectionPagingData$.next(val);
  }

  setPointsOnMapRendered(): void {
    this.pointsOnMapRendered$.next();
  }

  setloadPageOnMapFromMemory(pageIdx: number) {
    return this.loadPageOnMapFromMemory$.next(pageIdx);
  }  

  setViewPointsPanelToggle(isVisible: boolean) {
    return this.viewPointsPanelToggle$.next(isVisible);
  } 

  setSubscribedAreaPolygonPoints(val: L.LatLng[]) {
    this.subscribedAreaPolygonPoints$.next(val);
  }

  setActivateSubscribedArea(val: boolean) {
    return this.activateSubscribedArea$.next(val);
  }

  setDeactivateSubscribedArea(val: boolean) {
    return this.deactivateSubscribedArea$.next(val);
  }
  
  setToggleLeftSideBar(val: boolean) {
    return this.toggleLeftSideBar$.next(val);
  }

  setAddCollectionNavBackUrl(val: string) {
    return this.addCollectionNavBackUrl$.next(val);
  }

  setGoOutCollectionView() {
    return this.goOutCollectionView$.next();
  }

  setEmbeddedMode(val: boolean) {
    this.isEmbedded$.next(val);
  }
}
